@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Ubuntu:wght@300;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

input,
textarea {
  border: 0;
  outline: 0;
  background: none;
  font-family: Montserrat;
}

button {
  cursor: pointer;
  border: 0;
  background: 0;
  outline: 0;
}

#root {
  overflow: hidden;
}

[data-reach-dialog-content] {
  z-index: 3;
}
[data-reach-dialog-overlay] {
  z-index: 10;
}
